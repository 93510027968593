import feathers from "@feathersjs/client";
import { URL_BASE_API, URL_AUTHENTICATION } from "../constants";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
const socket = io(URL_BASE_API, {
  transports: ["websocket", "polling"], // Transportes permitidos
  withCredentials: true,
});
const app = feathers();
app.configure(
  socketio(socket, {
    timeout: 60000,
  })
);
app.configure(
  feathers.authentication({
    path: URL_AUTHENTICATION,
    entity: "user",
    service: "users",
    cookie: "feathers-jwt",
    storageKey: "feathers-jwt",
    storage: window.localStorage,
  })
);
/* app.io.on("connect", () => {
  socket.on("logactions created", (task) => {});
}); */
export default app;
