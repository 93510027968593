import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
export const URL_BASE_API =
  window.location.hostname === "localhost"
    ? "http://localhost:3030"
    : "https://api-dev-n21.gooneworld.com";
export const URL_AUTHENTICATION = "/authentication";
export const GOONEWORLD_SITE = "http://gooneworld.com";
export const URL_SITE = "http://closrx.com";
export const URL_S3 = process.env.REACT_APP_URL_S3;
export const DOMAIN =
  window.location.hostname === "localhost"
    ? "http://localhost:3000"
    : "https://closrx.com/";
export const URL_S3_SERVER = URL_BASE_API;
export const SIGIN_S3 = URL_S3_SERVER + "/s3Client/sign";
export const URL_LISTING_FAVORITES =
  process.env.REACT_APP_URL_LISTING_FAVORITES;
export const BUCKET = process.env.REACT_APP_BUCKET;
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const PRIVACY_AND_POLICY = "https://closrx.com/privacy-policy";
export const TERMS_AND_CONDITIONS = "https://closrx.com/terms-and-conditions";
export const URL_CDN = "https://imagecdn1.gooneworld.com";
export const URL_BASE_ACELLE = "https://mcloserx.com";
export const ACELLE_API_TOKEN = process.env.REACT_APP_ACELLE_API_TOKEN;
export const VERSION = "1.5.0";
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const URL_DEFAULT_AVATAR = "/images/placeholder.jpg";
export const LOGO_CLOSERX = "/images/logoCloseblue.png";
export const DEFAULT_IMAGE = "/images/ubicacion.png";
export const LOGO_BLUECLOSERX = "/images/logoCloseblue.png";
export const LOGO_CLOSERXBLACK = "/images/logoCloseblux.png";
export const IMAGE_UPLOAD = "/images/pictures.svg";
export const IMAGE_BANNER = "/images/manwoman.webp";
export const BANNER_MORTGAGE = "/images/bruno.jpeg";
export const IMAGE_WORKING = "/images/socialnew.png";
export const SIGNIN_IMAGE = "/images/closersignin.png";
export const IMAGE_MAC = "/images/bru.jpeg";
export const BACKGROUND_LINEAL = "/images/Grupobackground.svg";
// export const DEFAULT_IMAGE_BOARD = "/images/map_data.png";
export const DEFAULT_IMAGE_BOARD = "/images/person_process.png";
export const URL_LOGO_ICON = "/images/logo-icon.svg";
export const HOUSE_VOID = "/images/hogar.png";
export const extensions = ["png", "jpg", "jpeg", "jfif"];
export const permissions_guards =
  process.env.REACT_APP_PERMISSION_GUARD.split(",");
export const colors = [
  "transparent",
  "#00aecc",
  "#F17F66",
  "#86BFB9",
  "#E7C481",
  "#BEA7F1",
  "#828b91",
  "#24898E",
  "#93DA91",
  "#d29034",
  "#7D6F9B",
  "#8AA351",
  "#7EE2B8",
  "#88C393",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#F5CD47",
  "#F87168",
  "#C9372C",
  "#A7EEE2",
  "#999E9F",
  "#D804D8",
  "#D0F73D",
  "#3DF1F7",
  "#F72D73",
  "#F7802D",
  "#897E0A",
  "#25DF0D",
  "#ffea30",
  "#ffa117",
  "#BAC005",
  "#795548",
  "#707f8f",
  "#519839",
  "#b04632",
  "#00C36A",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#BDBF8D",

  /* "#4bbf6a",
  "#00aecc", */
];
export const colors_members = [
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91",
];
export const colors_trello = [
  // "transparent",
  "#d29034",
  "#519839",
  "#0079bf",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91",
];
export const colors_favro = [
  "transparent",
  "#6f63f4",
  "#2196f3",
  "#19c3d8",
  "#56b475",
  "#a9df4d",

  "#ffea30",
  "#ffa117",
  "#f5276d",
  "#795548",
  "#707f8f",
];
export const socials = {
  facebook: {
    text: "Facebook",
    name: "facebook",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "#3B5998",
    icon: <FaFacebook />,
    img: "/images/facebook.svg",
  },
  instagram: {
    text: "Instagram",
    name: "instagram",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "rgb(232, 62, 140)",
    icon: <IoLogoInstagram />,
    img: "/images/instagram.svg",
  },
  twitter: {
    text: "Twitter",
    name: "twitter",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "rgb(0 0 0 / 80%)",
    icon: <FaXTwitter />,
    img: "/images/social-x.png",
  },
  linkedin: {
    text: "Linkedin",
    name: "linkedin",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "#0077B7",
    icon: <FaLinkedin />,
    img: "/images/linkedin.svg",
  },
};
export const default_documents = [
  {
    title: "Executed Contract",
    name: "executed_contract",
  },
  {
    title: "Listing Agreement",
    name: "listing_agreement",
  },
  {
    title: "Escrow Letter",
    name: "escrow_letter",
  },
  {
    title: "Escrow Checks",
    name: "escrow_checks",
  },
  {
    title: "Closing Statement",
    name: "closing_statement",
  },
  {
    title: "Cancellation Form",
    name: "cancellation_form",
  },
  {
    title: "Agency Commission Check",
    name: "agency_commission_check",
  },
  {
    title: "Agent Commission Check",
    name: "agent_commission_check",
  },
  {
    title: "Addendum 1",
    name: "addendum_1",
  },
  {
    title: "Addendum 2",
    name: "addendum_2",
  },
  {
    title: "Addendum 3",
    name: "addendum_3",
  },
];
export const status = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
  {
    id: "accepted",
    name: "Accepted",
  },
  {
    id: "completed",
    name: "Completed",
  },
  {
    id: "in_progress",
    name: "In progress",
  },
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "canceled",
    name: "Canceled",
  },
];
export const tasks_types = [
  "task",
  "opportunity",
  "lead",
  "project",
  "target",
  "contact",
  "quote",
  "property",
  "email",
  "bug",
  "project_task",
  "target",
  "case",
  "meeting",
  "online_meeting",
  "call",
  "contract",
  "invoice",
  "quote",
  "product",
  "property",
  "event",
];
export const listing_status = ["Active", "ActiveUnderContract", "Pending"];

export const permission_guardss = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Broker",
    value: "broker",
  },
  {
    name: "Agent",
    value: "agent",
  },
];
export const types = [
  {
    text: "Lead",
    value: "lead",
  },
  {
    text: "Contact",
    value: "contact",
  },
  /*  {
    text: "Owner",
    value: "owner",
  }, */
];
export const temperatures = [
  {
    value: "quality",
    text: "Quality",
    background: "#f5276d",
    color: "#fff",
  },
  {
    value: "poorquality",
    text: "Poor",
    background: "#ffa117",
    color: "#fff",
  },
  {
    value: "cold",
    text: "Cold",
    background: "#2196f3",
    color: "#fff",
  },
  {
    value: "hot",
    text: "Hot",
    background: "#6f63f4",
    color: "#fff",
  },
  {
    value: "warm",
    text: "Warm",
    background: "#56b475",
    color: "#fff",
  },
  {
    value: "qualified",
    text: "Qualified",
    background: "#795548",
    color: "#fff",
  },
];
export const status_contact = [
  {
    value: "new",
    text: "New",
    background: "var(--teal) !important",
    color: "#ffffff",
  },
  {
    value: "hold",
    text: "Hold",
    background: "#d87d35",
    color: "#ffffff",
  },
  {
    value: "assigned",
    text: "Assigned",
    background: "var(--bg-color-pink)",
    color: "#ffffff",
  },
  {
    value: "follow_up",
    text: "Follow up",
    background: "#d44b42",
    color: "#ffffff",
  },
  {
    value: "in_process",
    text: "In Process",
    background: "#313F72",
    color: "#ffffff",
  },
  {
    value: "pre_qualification",
    text: "Pre-Qua",
    background: "#6f63f4",
    color: "#ffffff",
  },
  {
    value: "converted",
    text: "Converted",
    background: "#BC7809",
    color: "#ffffff",
  },
  {
    value: "recycled",
    text: "Recycled",
    background: "#A295A7",
    color: "#ffffff",
  },
  {
    value: "dead",
    text: "Dead",
    background: "#4094a1 ",
    color: "#ffffff",
  },
];
export const property_types = [
  {
    text: "Townhouse",
    value: "Townhouse",
  },
  {
    text: "Condo",
    value: "Condo",
  },
  {
    text: "Duplex",
    value: "Duplex",
  },
  {
    text: "Triplex",
    value: "Triplex",
  },
  {
    text: "Quaduplex",
    value: "Quaduplex",
  },
  {
    text: "Single Family",
    value: "Single Family",
  },
];
export const options = [
  {
    value: "new",
    text: "New",
    background: "var(--teal) !important",
    color: "#ffffff",
  },
  {
    value: "hold",
    text: "Hold",
    background: "#d87d35",
    color: "#ffffff",
  },
  {
    value: "assigned",
    text: "Assigned",
    background: "var(--bg-color-pink)",
    color: "#ffffff",
  },
  {
    value: "follow_up",
    text: "Follow up",
    background: "#d44b42",
    color: "#ffffff",
  },
  {
    value: "in_process",
    text: "In Process",
    background: "#313F72",
    color: "#ffffff",
  },
  {
    value: "pre_qualification",
    text: "Pre-Qua",
    background: "#6f63f4",
    color: "#ffffff",
  },
  {
    value: "converted",
    text: "Converted",
    background: "#BC7809",
    color: "#ffffff",
  },
  {
    value: "recycled",
    text: "Recycled",
    background: "#A295A7",
    color: "#ffffff",
  },
  {
    value: "dead",
    text: "Dead",
    background: "#4094a1 ",
    color: "#ffffff",
  },
];
export const mortgage_types = [
  {
    value: "fha",
    text: "FHA",
  },
  {
    value: "conventional",
    text: "Conventional",
  },
  {
    value: "nonqm",
    text: "NonQM",
  },
  {
    value: "hard_lender",
    text: "Hard Lender",
  },
  {
    value: "private",
    text: "Private Lender",
  },
];
export const term = [
  {
    value: "15_years",
    text: "15 Years",
  },
  {
    value: "30_years",
    text: "30 Years",
  },
  {
    value: "other",
    text: "Other",
  },
];
export const prePayPenalty = [
  {
    value: "not_applicable",
    text: "N/A",
  },
  {
    value: "1_years",
    text: "1 Year",
  },
  {
    value: "2_years",
    text: "2 Years",
  },
  {
    value: "3_years",
    text: "3 Years",
  },
  {
    value: "4_years",
    text: "4 Years",
  },
  {
    value: "5_years",
    text: "5 Years",
  },
];
export const homestead = [
  {
    value: "t",
    text: "Yes",
  },
  {
    value: "f",
    text: "No",
  },
];
export const status_account = [
  {
    value: "active",
    text: "Active",
  },
  {
    value: "inactive",
    text: "Inactive",
  },
];
export const types_account = [
  {
    value: "realstate",
    text: "Realstate",
  },
  {
    value: "mortgage",
    text: "Mortgage",
  },
];
